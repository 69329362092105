import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { serverURL } from '../constants';
import { Card, Spinner } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import found from '../res/img/found.svg';

const UserCourses = ({ userId }) => {
    const [courses, setCourses] = useState([]);
    const [processing, setProcessing] = useState(true);
    const navigate = useNavigate();

    const fetchUserCourses = async () => {
        const postURL = `${serverURL}/api/courses?userId=${userId}`;
        try {
            const response = await axios.get(postURL);
            setCourses(response.data);
            setProcessing(false);
        } catch (error) {
            console.error('Error fetching courses:', error);
            // Retry fetching courses
            fetchUserCourses();
        }
    };

    useEffect(() => {
        fetchUserCourses();
    }, [userId]);

    const redirectGenerate = () => {
        navigate("/create");
    };

    const handleCourse = (content, mainTopic, type, courseId, completed, end) => {
        const jsonData = JSON.parse(content);
        sessionStorage.setItem('courseId', courseId);
        sessionStorage.setItem('first', completed);
        sessionStorage.setItem('jsonData', JSON.stringify(jsonData));
        let ending = completed ? end : '';
        navigate('/course', { state: { jsonData, mainTopic: mainTopic.toUpperCase(), type: type.toLowerCase(), courseId, end: ending } });
    };

    const handleCertificate = (mainTopic, end) => {
        const ending = new Date(end).toLocaleDateString();
        navigate('/certificate', { state: { courseTitle: mainTopic, end: ending } });
    };

    const handleDelete = async (courseId) => {
        if (window.confirm('Are you sure you want to delete this course?')) {
            try {
                await axios.post(`${serverURL}/api/course/delete/${courseId}`);
                setCourses(prevCourses => prevCourses.filter(course => course._id !== courseId));
            } catch (error) {
                console.error('Error deleting course:', error);
                alert('Failed to delete course. Please try again.');
            }
        }
    };

    const style = {
        "root": {
            "base": "max-w-sm flex rounded-none border border-black bg-white shadow-none dark:border-white dark:bg-black m-4",
            "children": "flex h-full flex-col justify-center gap-3 p-5",
            "horizontal": {
                "off": "flex-col",
                "on": "flex-col md:max-w-xl md:flex-row"
            },
            "href": "hover:bg-white dark:hover:bg-black"
        },
        "img": {
            "base": "",
            "horizontal": {
                "off": "rounded-none",
                "on": "h-96 w-full rounded-none object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
            }
        }
    };

    if (processing) {
        return (
            <div className="text-center h-screen w-screen flex items-center justify-center">
                <Spinner size="xl" className='fill-black dark:fill-white' />
            </div>
        );
    }

    if (courses.length === 0) {
        return (
            <div className="text-center h-center flex flex-col items-center justify-center">
                <img alt='img' src={found} className='max-w-sm h-3/6' />
                <p className='text-black font-black dark:text-white text-xl'>Nothing Found</p>
                <button onClick={redirectGenerate} className='bg-black text-white px-5 py-2 mt-4 font-medium dark:bg-white dark:text-black'>
                    Generate Course
                </button>
            </div>
        );
    }

    return (
        <div className='my-4 flex flex-wrap justify-center'>
            {courses.map((course) => (
                <Card
                    key={course._id}
                    imgSrc={course.photo}
                    theme={style}
                >
                    <h5 className='text-xl font-black tracking-tight text-black dark:text-white'>
                        {course.mainTopic.toUpperCase()}
                    </h5>
                    <p className='font-normal text-sm capitalize text-black dark:text-white'>
                        {course.type}
                    </p>
                    <p className='font-normal text-sm text-black dark:text-white'>
                        {new Date(course.date).toLocaleDateString()}
                    </p>
                    <div className='flex-row flex space-x-4'>
                        <button
                            onClick={() => handleCourse(course.content, course.mainTopic, course.type, course._id, course.completed, course.end)}
                            className='bg-black text-white px-5 py-2 font-medium dark:bg-white dark:text-black'
                        >
                            Continue
                        </button>
                        <button
                            onClick={() => handleDelete(course._id)}
                            className='bg-red-500 text-white px-5 py-2 font-medium hover:bg-red-600'
                        >
                            Delete
                        </button>
                        {course.completed && (
                            <button
                                onClick={() => handleCertificate(course.mainTopic, course.end)}
                                className='border-black text-black border px-5 py-2 font-medium dark:border-white dark:text-white'
                            >
                                Certificate
                            </button>
                        )}
                    </div>
                </Card>
            ))}
        </div>
    );
};

export default UserCourses;