import React from 'react';
import styled from 'styled-components';
import EventQuizModal from './EventQuizModal';
import { Navigate, useNavigate } from 'react-router-dom';
// Styled components for the modal
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; /* Added z-index property */
`;
const ModalButton = styled.button`
  padding: 10px 20px;
  background-color: #135D66;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
`;
const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 600px;
  max-height: 80%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const CloseButton = styled.button`
  align-self: flex-end;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;


const EventModal = ({ userSchedule, userId, event, onClose }) => {

  const navigate = useNavigate();
  if (!event) return null;

  const redirectCreate = () => {
    // Close the modal first
    onClose();

    // Use setTimeout to ensure the navigation happens after the modal is closed
    setTimeout(() => {
      navigate('/create', { state: { eventTitle: event.title } });
    }, 0);
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <h2 style={{ fontSize: '1.2rem', marginBottom: '20px', textAlign: 'center', fontWeight: 'bold' }}>
          Event Details
        </h2>

        <div style={{ textAlign: "center" }} >
          <p><strong>Title:</strong> {event.title}</p>
          <p><strong>Start:</strong> {event.start}</p>
          <p><strong>End:</strong> {event.end}</p>
        </div>
        <EventQuizModal userSchedule={userSchedule} userId={userId} event={event}  eventTitle={event.title} />
        {(event.subject === 'break' || event.subject === 'Break') && (
          <ModalButton className='mt-3' onClick={redirectCreate}>
            Generate Course
          </ModalButton>
        )}
      </ModalContent>
    </ModalOverlay >
  );
};

export default EventModal;
