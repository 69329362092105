import React from "react";
import PricingPlan from "../pricing";
import { FreeType, FreeCost, FreeTime, MonthType, MonthTime, MonthCost, YearType, YearCost, YearTime } from '../../constants';

const SlideFour = () => {

    const freeData = {
        type: "Free Plan",
        cost: FreeCost,
        time: "month",
        features: [
            "Generate 5 Sub-Topics Maximum",
            "Limited Detailed Contents",
            "Video & Theory",
            "Lifetime access",
            "Create two courses only"
        ]
    };
    // const parsedFreeData = JSON.parse(freeData);

    const monthData = {
        type: "Monthly Plan",
        cost: MonthCost,
        time: "month",
        features: {
            "Personal Customized Learning Consultant": [
                "Obtain an AI Consultant that truly understands \"you\"",
                "Make Unlimited Study Plans",
                "Guaranteed Growth in Learning",
                "Essay Exam Planner",
                "24 Hour Supervision on your Learning Journey",
                "Weekly/Daily Reminders From Our Consultant AI",
                "Weekly/Daily Exercises for Best Preparation"
            ],
            "Course generation": [
                "Generate up to 10 Customizable Sub-Topics",
                "Create Unlimited Course",
                "Video & Theory"
            ],
            "Course Quiz": [
                "Generate Unlimited Quiz",
                "Access to all difficulties and types of questions in exams",
                "Well trained 24-Hour Learning Assistant ready to answer every question"
            ]
        }
    };
    const YearData = `{"type": "${YearType}", "cost": "${YearCost}", "time": "/${YearTime}", "one": "Generate 10 Sub-Topics Maximum", "two": "Create Unlimited Course", "three": "Video & Theory ", "four": "Yearly Access", "five": "Theory & Image Course"}`;
    const parsedYearData = JSON.parse(YearData);

    return (
        <div className="dark:bg-black py-14">
            <div className="px-11 items-start justify-start text-start">
                <div className="text-4xl max-md:text-2xl font-black dark:text-white">Pricing</div>
                <p className="py-3 font-medium max-md:text-xs dark:text-white">Choose the right plan for your education and future</p>
            </div>
            <div className="lg:flex py-10 ">

                <div className="flex flex-col items-center justify-center lg:w-1/2 max-md:pt-4">
                    <PricingPlan data={freeData} />
                </div>

                <div className="flex flex-col items-center justify-center lg:w-1/2 max-md:pt-4">
                    <PricingPlan data={monthData} />
                </div>

                <div className="flex flex-col items-center justify-center lg:w-1/3 max-md:pt-4 hidden">
                    {/* <PricingPlan data={parsedYearData} /> */}
                </div>
            </div>
        </div>
    );
};

export default SlideFour;
