import { getMessaging } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyD31IqRzKvQA8hqLv4LXO2atOYCtOJTMlI",
    authDomain: "learnooza-16269.firebaseapp.com",
    projectId: "learnooza-16269",
    storageBucket: "learnooza-16269.appspot.com",
    messagingSenderId: "631539117340",
    appId: "1:631539117340:web:3b41da15ad581966935ed9",
    measurementId: "G-N07M3TXESC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging };