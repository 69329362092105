import React, { useState } from 'react';
import Header from '../components/header';
import Footers from '../components/footers';
import { FreeType, FreeCost, FreeTime, MonthType, MonthTime, MonthCost, YearType, YearCost, YearTime } from '../constants';
import PricingPlan from '../components/pricing';
import { GiCancel } from "react-icons/gi";
import { FaRankingStar } from "react-icons/fa6";
import { FaInfinity } from "react-icons/fa6";
import { Button, Card } from 'flowbite-react';
import { useLocation, useNavigate } from 'react-router-dom';

const Pricing = () => {

    const { state } = useLocation();
    const { header } = state || false;

    const freeData = {
        type: "Free Plan",
        cost: FreeCost,
        time: "month",
        features: [
            "Generate 5 Sub-Topics Maximum",
            "Limited Detailed Contents",
            "Video & Theory",
            "Lifetime access",
            "Create two courses only"
        ]
    };
    // const parsedFreeData = JSON.parse(freeData);

    const monthData = {
        type: "Monthly Plan",
        cost: MonthCost,
        time: "month",
        features: {
            "Personal Customized Learning Consultant": [
                "Obtain an AI Consultant that truly understands \"you\"",
                "Make Unlimited Study Plans",
                "Guaranteed Growth in Learning",
                "Essay Exam Planner",
                "24 Hour Supervision on your Learning Journey",
                "Weekly/Daily Reminders From Our Consultant AI",
                "Weekly/Daily Exercises for Best Preparation"
            ],
            "Course generation": [
                "Generate up to 10 Customizable Sub-Topics",
                "Create Unlimited Course",
                "Video & Theory"
            ],
            "Course Quiz": [
                "Generate Unlimited Quiz",
                "Access to all difficulties and types of questions in exams",
                "Well trained 24-Hour Learning Assistant ready to answer every question"
            ]
        }
    };
    // const parsedMonthData = JSON.parse(monthData);

    const YearData = `{"type": "${YearType}", "cost": "${YearCost}", "time": "/${YearTime}", "one": "Generate 10 Sub-Topics Maximum", "two": "Create Unlimited Course", "three": "Video & Theory", "four": "Yearly Access"}`;
    const parsedYearData = JSON.parse(YearData);

    const navigate = useNavigate();

    function redirectCancel() {
        navigate("/cancellation");
    }

    function redirectRefund() {
        navigate("/refund");
    }

    function redirectBilling() {
        navigate("/billing");
    }

    function redirectContact() {
        navigate("/contact");
    }

    const data = [
        { question: 'What is our cancellation policy ?', answer: <p>You can cancel anytime</p> },
        { question: 'What is our refund policy ?', answer: <p>Non Refundable</p> },
        { question: 'What is our subscription and billing policy ?', answer: <p>Subscription: Cancel Anytime. Billing: Recurring Payment.</p> },
        { question: 'What are the available payment gateways ?', answer: 'Our platform supports multiple payment gateways, including PayPal, and Razorpay.' },
    ];

    const [expandedItem, setExpandedItem] = useState(null);

    const handleExpand = (index) => {
        setExpandedItem(expandedItem === index ? null : index);
    };

    const style = {
        "root": {
            "base": "max-w-sm max-md:max-w-xs flex rounded-none border border-black bg-white shadow-none dark:border-white dark:bg-black m-4",
            "children": "flex h-full flex-col justify-center gap-3 p-5",
            "horizontal": {
                "off": "flex-col",
                "on": "flex-col md:max-w-xl md:flex-row"
            },
            "href": "hover:bg-white dark:hover:bg-black"
        },
        "img": {
            "base": "",
            "horizontal": {
                "off": "rounded-none",
                "on": "h-96 w-full rounded-none object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
            }

        }
    }

    return (
        <div className='h-screen flex flex-col'>
            <Header isHome={header} className="sticky top-0 z-50" />
            <div className='dark:bg-black flex-1'>
                <div className='flex-1 flex flex-col items-center justify-center'>
                    <h1 className="text-6xl font-black mt-14 max-md:text-3xl dark:text-white">Pricing Plan</h1>
                    <p className="text-center text-black mt-6 max-w-2xl font-medium max-md:text-xs dark:text-white">
                        Choose the Perfect Plan for Your Success
                    </p>
                    <div className="lg:flex py-14">
                        <div className="flex flex-col items-center justify-center lg:w-1/2 px-3 max-md:pt-4">
                            <PricingPlan data={freeData} />
                        </div>
                        <div className="flex flex-col items-center justify-center lg:w-1/2 px-3 max-md:pt-4">
                            <PricingPlan data={monthData} />
                        </div>
                    </div>
                    {/* ... (rest of your component JSX) */}
                </div>
            </div>
            <Footers className="sticky bottom-0 z-50" />
        </div>
    );
};

export default Pricing;
