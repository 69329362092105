import React, { useState, useEffect } from 'react';

const RandomNumber = () => {
    const [randomNumber, setRandomNumber] = useState(0);

    useEffect(() => {
        // Function to generate a random number between 30 and 150
        const generateRandomNumber = () => {
            const min = 30;
            const max = 150;
            return Math.floor(Math.random() * (max - min + 1)) + min;
        };

        // Check if 3 minutes have passed since the last number generation
        const checkTimeElapsed = () => {
            const savedTimestamp = localStorage.getItem('randomNumberTimestamp');
            const now = Date.now();

            if (savedTimestamp) {
                const timeDifference = now - parseInt(savedTimestamp, 10);
                // 3 minutes = 3 * 60 * 1000 milliseconds
                return timeDifference >= 3 * 60 * 1000;
            }

            return true; // If no timestamp exists, generate a new number
        };

        // Set random number if 3 minutes have passed or no number exists
        if (checkTimeElapsed()) {
            const newRandomNumber = generateRandomNumber();
            setRandomNumber(newRandomNumber);
            localStorage.setItem('randomNumber', newRandomNumber);
            localStorage.setItem('randomNumberTimestamp', Date.now().toString());
        } else {
            // Retrieve the existing random number
            const storedNumber = localStorage.getItem('randomNumber');
            setRandomNumber(storedNumber ? parseInt(storedNumber, 10) : generateRandomNumber());
        }

        // Optional: You could also set an interval to check every 3 minutes if a new number should be generated
        const intervalId = setInterval(() => {
            if (checkTimeElapsed()) {
                const newRandomNumber = generateRandomNumber();
                setRandomNumber(newRandomNumber);
                localStorage.setItem('randomNumber', newRandomNumber);
                localStorage.setItem('randomNumberTimestamp', Date.now().toString());
            }
        }, 3 * 60 * 1000); // 3 minutes

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className='px-10' style={{ marginTop: "-30px" }}>
            <h2 className='text-2xl font-bold mb-4 border-black border-2 w-fit px-2 py-1'>
                Online Students: {randomNumber}
            </h2>
        </div>
    );
};

export default RandomNumber;
