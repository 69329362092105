import React, { useState } from 'react';
import AiModal from './AiModal';
import QuestionModal from './questionModal';

const StudyToolsDropdown = ({ redirectGenerate, redirectCalendar, openSurveyModal }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => setIsOpen(!isOpen);

    

    return (
        <div className="relative">
            <button
                onClick={toggleDropdown}
                className='border-b-0 text-white  font-normal mb-0 mt-2 bg-black dark:text-black dark:bg-white  hover:bg-black dark:hover:bg-white md:dark:hover:bg-white md:hover:bg-black hover:text-white md:hover:text-white  dark:hover:text-black dark:md:hover:text-black' style={{ paddingLeft: '15px', paddingRight: '15px', paddingBottom: '10px', paddingTop: '10px' }}
            >
                Study Tools
                <span className="ml-2">{isOpen ? '▲' : '▼'}</span>
            </button>
            {isOpen && (
                <div className="absolute left-0 mt-2 w-full flex flex-col bg-white  px-1">

                    <button onClick={redirectGenerate} className='border-b-0 text-white  font-normal mb-1  bg-black dark:text-black dark:bg-white  hover:bg-black dark:hover:bg-white md:dark:hover:bg-white md:hover:bg-black hover:text-white md:hover:text-white dark:hover:text-black dark:md:hover:text-black' style={{ paddingLeft: '15px', paddingRight: '15px', paddingBottom: '10px', paddingTop: '10px' }}>
                        Generate Course
                    </button>
                    <AiModal></AiModal>
                    <QuestionModal></QuestionModal>
                </div>
            )}
        </div>
    );
};

export default StudyToolsDropdown;