import React, { useState, useEffect } from 'react';

import image1 from '../../res/img/unlock-image-1.png';
import image2 from '../../res/img/unlock-image-2.png';
import image3 from '../../res/img/unlock-image-3.png';
import image4 from '../../res/img/unlock-image-4.png';
import { FaUsers, FaBook, FaQuestionCircle, FaComments, FaChevronDown } from 'react-icons/fa';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const SlideTwo = () => {
    const stats = [
        { label: 'Active Users', value: '700+', icon: <FaUsers /> },
        { label: 'Courses Created', value: '1500+', icon: <FaBook /> },
        { label: 'Quizzes Created', value: '4000+', icon: <FaQuestionCircle /> },
        { label: 'Conversations Created', value: '10000+', icon: <FaComments /> },
    ];
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % features.length);
        }, 3000);

        return () => clearInterval(timer);
    }, []);

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + features.length) % features.length);
    };

    const goToNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % features.length);
    };
    const features = [
        {
            title: "Newly Integrated Course Generation",
            description: "Course Displayed With PEE (Point Example Evidence) Format, Integrated With Video Lectures Will Foster Your Learning Experience.",
            image: image1
        },
        {
            title: "Automated AI Quiz",
            description: "With Our Well-Trained AI, We Can Generate Infinite Quizzes And Answers Within Seconds That Will Meet Your Expectations.",
            image: image2
        },
        {
            title: "24-Hour Private AI Learning Assistant",
            description: "Our AI Covers Any Topics. It Also Remembers Your Course And Will Answer Every Question, Anytime Anywhere.",
            image: image3
        },
        {
            title: " Private AI Learning Supervisor.",
            description: "Like a private teacher, our AI with customized calendar will be generating optimal plans for your learning journey, and it will be Supervising your learning each day, Making sure you are with the right pace, Guaranteeing your learning improvements.",
            image: image4
        },
    ];
    return (

        <div className="px-7 mt-20 justify-center items-center pb-10 dark:bg-black">
            <div id="slideTwo" className="flex flex-col items-center justify-center dark:bg-black py-16 dark:text-white" style={{ minHeight: "70vh" }}>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-12 w-full max-w-7xl px-8">
                    {stats.map((stat, index) => (
                        <div key={index} className="text-center flex flex-col items-center">
                            <div className="text-7xl mb-4">{stat.icon}</div>
                            <div className="text-5xl font-bold mb-2">{stat.value}</div>
                            <div className="text-xl mt-2">{stat.label}</div>
                        </div>
                    ))}
                </div>

                {/* Uncomment if you want to include images */}
                {/* <div className="mx-auto w-full max-w-7xl grid grid-cols-1 md:grid-cols-2 gap-8 mt-16 px-8">
                <img src={slide} className="w-full object-cover h-96" alt="Slide 1" />
                <img src={slideTwo} className="w-full object-cover h-96" alt="Slide 2" />
            </div> */}
            </div>
            <h2 className="text-4xl font-black mb-10 text-center max-md:text-2xl dark:text-white">
                Unlock The New Way Of Learning
            </h2>

            <div className="relative w-full h-screen overflow-hidden">
                <div
                    className="flex transition-transform duration-500 ease-in-out"
                    style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                >
                    {features.map((feature, index) => (
                        <div key={index} className="w-full h-screen flex-shrink-0">
                            <div className="flex flex-col md:flex-row items-center h-full">
                                <div className="md:w-1/2 p-8 flex flex-col items-center md:items-start justify-center">
                                    <h3 className="text-3xl font-bold mb-4 dark:text-white">{feature.title}</h3>
                                    <p className="text-lg mb-4 text-center md:text-left dark:text-white">{feature.description}</p>
                                </div>
                                <div
                                    className="md:w-1/2 h-full bg-contain bg-no-repeat bg-center"
                                    style={{ backgroundImage: `url(${feature.image})` }}
                                    aria-label={feature.title}
                                />

                            </div>
                        </div>
                    ))}
                </div>
                <button
                    onClick={goToPrevious}
                    className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2 focus:outline-none"
                >
                    <FaChevronLeft className="text-2xl" />
                </button>
                <button
                    onClick={goToNext}
                    className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2 focus:outline-none"
                >
                    <FaChevronRight className="text-2xl" />
                </button>
            </div>
        </div>
    );
};

export default SlideTwo;