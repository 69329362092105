import React, { useState } from 'react';
import Header from '../components/header';
import Footers from '../components/footers';
import UserCourses from '../components/usercourses';
import { Layers, BookOpen, FileQuestion } from 'lucide-react';
import UserQuizzes from '../components/UserQuizzes';
const AccordionItem = ({ title, children, icon: Icon }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="mb-6">
            <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden">
                <button
                    className="w-full p-4 text-left bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors duration-150 ease-in-out flex items-center"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <div className="flex-shrink-0 mr-4 bg-blue-100 dark:bg-blue-900 p-2 rounded-full">
                        <Icon className="w-6 h-6 text-primary dark:text-white" />
                    </div>
                    <span className="flex-grow font-medium text-gray-800 dark:text-white">{title}</span>
                    <svg
                        className={`w-5 h-5 text-gray-500 transform transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                </button>
            </div>
            {isOpen && (
                <div className="mt-2 w-full bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden">
                    <div className="p-4 w-full">
                        {children}
                    </div>
                </div>
            )}
        </div>
    );
};

const Home = () => {
    return (
        <div className='min-h-screen flex flex-col bg-gray-100 dark:bg-gray-900'>
            <Header isHome={true} className="sticky top-0 z-50" />
            <div className='flex-1 p-4'>
                <h1 className="text-2xl font-bold mb-6 text-gray-800 dark:text-white">Dashboard</h1>
                <div className=''>
                    <AccordionItem title="Courses" icon={BookOpen}>
                        <UserCourses userId={sessionStorage.getItem('uid')} />
                    </AccordionItem>

                    <AccordionItem title="Quiz" icon={FileQuestion}>
                        <div className="text-gray-600 dark:text-gray-300">
                            <UserQuizzes />
                        </div>
                    </AccordionItem>

                </div>
            </div>
            <Footers className="mt-auto" />
        </div>
    );
};

export default Home;