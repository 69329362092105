import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import Footers from '../components/footers';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import axios from 'axios';
import { serverURL } from '../constants';
import EventModal from './EventModal';
import styled from 'styled-components';
import QuizModal from './QuizModal';

const CalendarPage = () => {
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null); // State to hold the selected event
    const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
    const userId = sessionStorage.getItem('uid'); // Get user ID from session storage
    const Button = styled.button`
    padding: 10px 20px; 
    font-size: 16px;
    background-color: #135D66;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    `;
    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get(serverURL + `/api/get-study-plan/${userId}`);
                const studyPlan = response.data.studyPlan;

                // Format start time
                const formatTime = (date) => {
                    const options = { hour: '2-digit', minute: '2-digit', hour12: false };
                    return new Intl.DateTimeFormat('en-US', options).format(date);
                };

                // Map study plan to FullCalendar events format
                const formattedEvents = studyPlan.map(item => {
                    const startDate = new Date(item.date.split('-').reverse().join('-') + 'T' + item.time);
                    const endDate = new Date(item.date.split('-').reverse().join('-') + 'T' + item.endtime);
                    const formattedStartTime = formatTime(startDate);

                    return {
                        title: `${formattedStartTime} - ${item.subject}${item.task ? ` - ${item.task}` : ''}`,
                        start: startDate,
                        end: endDate,
                        extendedProps: {
                            description: item.description, // Assuming there's a description in the data
                            task: item.task, // Additional details can be added here
                        }
                    };
                });

                setEvents(formattedEvents);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };


        fetchEvents();
    }, [userId]);

    // Handler for when an event is clicked
    const handleEventClick = (info) => {
        const event = info.event;
        setSelectedEvent({
            title: event.title,
            start: event.start.toLocaleString(),
            end: event.end ? event.end.toLocaleString() : 'N/A',
            description: event.extendedProps.description || 'No description available',
            task: event.extendedProps.task || 'No task specified',
        });
        setIsModalOpen(true); // Show the modal
    };

    // Handler to close the modal
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedEvent(null);
    };

    // Utility function to truncate the title
    const truncateTitle = (title, maxLength = 20) => {
        return title.length > maxLength ? title.substring(0, maxLength) + '...' : title;
    };


    return (
        <div className='h-screen flex flex-col'>
            <Header isHome={false} className="sticky top-0 z-50" />
            <div className='flex flex-col flex-1 mx-2'>
              
                <div className='flex justify-end mb-2'>
                    {events.length > 0 && (
                        <QuizModal userSchedule={events} userId={userId} />
                    )}

                </div>
                <div className='flex-1'>
                    <FullCalendar
                        plugins={[dayGridPlugin]}
                        initialView="dayGridMonth"
                        events={events}
                        height="100vh" // Ensures the calendar takes full viewport height
                        contentHeight="auto" // Adjusts the content height to fit the events
                        aspectRatio={1.5} // Controls the width-to-height ratio
                        eventClick={handleEventClick} // Adding the click handler here
                        eventContent={(eventInfo) => (
                            <div>{truncateTitle(eventInfo.event.title)}</div>
                        )}
                    />
                </div>
            </div>

            <Footers className="sticky bottom-0 z-50" />

            {/* Modal for displaying event details */}
            {isModalOpen && (
                <EventModal userSchedule={events} userId={userId} event={selectedEvent} onClose={closeModal} />
            )}
        </div>
    );
};

export default CalendarPage;
