import React from "react";
import { PiKeyboard, PiVideo, PiDownloadBold } from "react-icons/pi";
import { RiAiGenerate } from "react-icons/ri";
import { FaQuestionCircle, FaInfinity, FaRobot, FaBookOpen } from "react-icons/fa";

const SlideThree = () => {
    return (
        <div className="dark:bg-black">
            <div className="flex items-center justify-center text-center">
                <div className="text-4xl font-black dark:text-white max-md:text-2xl">How it works</div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 py-24 max-md:py-14 text-center">
                <div className="flex flex-col items-center justify-center">
                    <FaQuestionCircle className="text-4xl max-md:text-3xl dark:text-white" />
                    <div className="text-2xl max-md:text-lg pt-4 font-bold dark:text-white">Respond to Survey</div>
                    <div className="text-lg max-md:text-xs text-center px-3 pt-2 font-medium dark:text-white">Our AI will know your preferences and habits to give you the most accurate assistance.</div>
                </div>

                <div className="flex flex-col items-center justify-center">
                    <RiAiGenerate className="text-4xl max-md:text-3xl dark:text-white" />
                    <div className="text-2xl max-md:text-lg pt-4 font-bold dark:text-white">AI Planner</div>
                    <div className="text-lg max-md:text-xs text-center px-3 pt-2 font-medium dark:text-white">Our AI will generate plans for you, and our AI will be supervising your learning journey.</div>
                </div>

                <div className="flex flex-col items-center justify-center">
                    <FaRobot className="text-4xl max-md:text-3xl dark:text-white" />
                    <div className="text-2xl max-md:text-lg pt-4 font-bold dark:text-white">24 Hour Assistance</div>
                    <div className="text-lg max-md:text-xs text-center px-3 pt-2 font-medium dark:text-white">Our AI will be contacting you through email, SMS… (more apps coming up) to make sure you are on track of your learning journey.</div>
                </div>

                <div className="flex flex-col items-center justify-center">
                    <PiDownloadBold className="text-4xl max-md:text-3xl dark:text-white" />
                    <div className="text-2xl max-md:text-lg pt-4 font-bold dark:text-white">Unlimited Course Generation</div>
                    <div className="text-lg max-md:text-xs text-center px-3 pt-2 font-medium dark:text-white">Our AI will pick the right resources and make them into teaching/learning materials for you. All courses are downloadable/sharable.</div>
                </div>

                <div className="flex flex-col items-center justify-center">
                    <FaQuestionCircle className="text-4xl max-md:text-3xl dark:text-white" />
                    <div className="text-2xl max-md:text-lg pt-4 font-bold dark:text-white">Get Infinite Tests with Qualified Questions</div>
                    <div className="text-lg max-md:text-xs text-center px-3 pt-2 font-medium dark:text-white">Our AI will generate infinite tests and assess your knowledge/progress with high-quality curated questions.</div>
                </div>

                <div className="flex flex-col items-center justify-center">
                    <FaBookOpen className="text-4xl max-md:text-3xl dark:text-white" />
                    <div className="text-2xl max-md:text-lg pt-4 font-bold dark:text-white">Ask Learnooza AI Anything</div>
                    <div className="text-lg max-md:text-xs text-center px-3 pt-2 font-medium dark:text-white">Our 24/7 Learnooza AI is able to respond to any type of questions and explain anything with concise wording.</div>
                </div>
            </div>
        </div>
    );
};

export default SlideThree;
